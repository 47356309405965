<template>
  <section class="log-in">
    <div class="log-in__form">
      <form action="" class="form" @submit.prevent="submit">
        <h1>Восстановление пароля</h1>

        <template v-if="errorMessage">
          <p>{{ errorMessage }}</p>
        </template>

        <template v-if="successMessage">
          <p>{{ successMessage }}</p>
        </template>

        <div class="form__row row">
          <div class="col-lg-12">
            <input
              type="text"
              placeholder="Ваша электроннная почта"
              required
              v-model="login"
              class="personal__input personal__form-text"
            >
          </div>
        </div>

        <div class="form__row row align-items-center">
          <div class="col-md-6 col-12 text-sm-center">
            <button
              class="btn btn--login"
              type="submit"
              v-if="!btnLoading"
            >Отправить</button>

            <Preloader v-if="btnLoading"/>
          </div>

          <div class="col-md-6 col-12 text-center">
            <router-link
              to="/"
              class="form__link"
            >Авторизоваться</router-link>
          </div>
        </div>
      </form>
    </div>

    <div class="bg-figures"></div>
  </section>
</template>

<script>
import axios from 'axios';
import Preloader from '@/components/Preloader.vue';

export default {
  name: 'RecoveryPassword',
  components: {
    Preloader,
  },
  data() {
    return {
      successMessage: '',
      errorMessage: '',
      login: '',
      btnLoading: false,
    };
  },
  computed: {
    checkForm() {
      return this.login;
    },
    apiUrl() {
      return this.$store.getters.getApiUrl;
    },
  },
  methods: {
    submit() {
      this.errorMessage = '';
      this.successMessage = '';

      if (this.checkForm) {
        this.btnLoading = true;

        const url = new URL(this.apiUrl);
        url.searchParams.set('action', 'sendPassword');

        const params = new URLSearchParams();
        params.append('login', this.login);

        axios
          .post(url, params)
          .then((response) => {
            const { data } = response.data;

            if (data.success) {
              this.successMessage = data.message;
            } else {
              this.errorMessage = data.message;
            }

            this.btnLoading = false;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.errorMessage = 'Ошибка!';
      }
    },
  },
};
</script>
