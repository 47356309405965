<template>
  <RecoveryPasswordForm />
</template>

<script>
import RecoveryPasswordForm from '@/components/RecoveryPassword.vue';

export default {
  name: 'RecoveryPassword',
  components: {
    RecoveryPasswordForm,
  },
};
</script>
